<template>
  <b-card>

    <b-modal
      id="franchise-revenue-modal"
      :title="`${updateId ? 'Update' : 'Add'} VAT Config`"
      size="lg"
      :ok-title="`${updateId ? 'Update' : 'Save'}`"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleVatConfigFormSubmit"
      @cancel="resetVatConfig"
      @close="resetVatConfig"
      @ok.prevent
    >
      <b-form @submit.prevent>
        <b-row>
          <!-- Months List -->
          <b-col cols="12">
            <b-form-group
              label="Select Month"
              label-cols-md="4"
              :state="monthsState"
              invalid-feedback="Month is required"
            >
              <b-form-checkbox-group
                v-model="vatConfigFormData.months"
                :options="vatConfigMonths"
                value-field="id"
                text-field="name"
                :state="monthsState"
              />
            </b-form-group>
          </b-col>
          <!-- VAT Percentage -->
          <b-col cols="12">
            <b-form-group
              label="VAT Percentage"
              label-cols-md="4"
              :state="percentageState"
              invalid-feedback="Percentage is required"
            >
              <b-form-input
                v-model="vatConfigFormData.percentage"
                type="number"
                placeholder="Enter VAT Percentage"
                :state="percentageState"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>

    <div class="d-flex justify-content-between">
      <p class="card-title ">
        VAT Config
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          style="text-transform: capitalize"
          @click="openModal"
        >
          Add VAT Config
        </b-button>
      </b-form-group>
      <!-- Session Filter Dropdown -->
      <b-form-group
        label-for="pay-cycle-config-session"
        class="pr-0 col-lg-3 col-md-4"
      >
        <v-select
          v-model="vatConfigSessionId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          placeholder="Select Session"
          :options="vatConfigSessionOptions"
          :reduce="val => val.id"
          @input="changeSession"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <span v-if="props.column.field === 'status'">
          {{ props.formattedRow[props.column.field] == 1 ? 'Active' : 'Inactive' }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormCheckboxGroup, BFormInput, BForm, BFormSelect, BPagination, BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { RefreshCwIcon } from 'vue-feather-icons'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BForm,
    BCard,
    vSelect,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    RefreshCwIcon,
    BLink,
    ActivityModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      columns: [
        {
          label: 'Academic Session',
          field: 'session.name',
        },
        {
          label: 'Month',
          field: 'months.names',
        },
        {
          label: 'VAT (%)',
          field: 'vat_percentage',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      vatConfigFormData: {
        months: [],
        percentage: null,
      },
      vatConfigSessionId: null,
      campusOptions: [],
      classOptions: [],
      vatConfigSessionOptions: [],
      vatConfigMonths: [],

      monthsState: null,
      percentageState: null,

      tag: 'vat-config',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getVatConfigSession()
  },
  methods: {
    changeSession(val) {
      if (!val) {
        this.rows = []
        return
      }
      this.vatConfigSessionId = val
      this.getVatConfigGridData()
    },
    getVatConfigSession() {
      const token = localStorage.getItem('accessToken')
      this.$http.get(`${window.apiUrl}accounting/vat-config/session`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          if (res.data.status) {
            this.vatConfigSessionOptions = res.data.data
            this.vatConfigSessionId = this.vatConfigSessionOptions.find(x => x.is_current === 1).id
            this.getVatConfigGridData()
          }
        })
    },
    getCampus() {
      this.$http
        .get(`${window.apiUrl}accounting/franchise-revenue-config/campus`)
        .then(res => {
          if (res.data.status) {
            this.campusOptions = res.data.data
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    getClass() {
      this.$http
        .get(`${window.apiUrl}accounting/franchise-revenue-config/class`)
        .then(res => {
          if (res.data.status) {
            this.classOptions = res.data.data
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    getVatConfigMonths() {
      const token = localStorage.getItem('accessToken')
      this.$http.get(`${window.apiUrl}accounting/vat-config/months`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          this.vatConfigMonths = res.data.data
        })
    },
    handleVatConfigFormSubmit() {
      if (this.updateId) {
        this.updateVatConfig()
      } else {
        this.saveVatConfig()
      }
    },
    validate() {
      this.monthsState = null
      this.percentageState = null

      if (!this.vatConfigFormData.months || (this.vatConfigFormData.months && this.vatConfigFormData.months.length < 0)) {
        this.monthsState = false
      }

      if (!this.vatConfigFormData.percentage || this.vatConfigFormData.percentage === null || this.vatConfigFormData.percentage === '') {
        this.percentageState = false
      }

      if (this.monthsState !== false && this.percentageState !== false) {
        return true
      }
      return false
    },
    saveVatConfig() {
      if (!this.validate()) {
        return
      }
      const params = {
        acaSessionId: this.vatConfigSessionId,
        months: JSON.stringify(this.vatConfigFormData.months),
        vatPercentage: this.vatConfigFormData.percentage,
        status: 1,
      }

      const token = localStorage.getItem('accessToken')
      this.$http
        .post(`${window.apiUrl}accounting/vat-config`, params, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.data.status) {
            this.resetVatConfig()
            this.getVatConfigGridData()
            this.$bvModal.hide('franchise-revenue-modal')
            FemsToastrService.success(res?.data.message)
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    updateVatConfig() {
      if (!this.validate()) {
        return
      }
      const params = {
        acaSessionId: this.vatConfigSessionId,
        months: JSON.stringify(this.vatConfigFormData.months),
        vatPercentage: this.vatConfigFormData.percentage,
        status: this.vatConfigFormData.status,
      }
      this.$http
        .put(`${window.apiUrl}accounting/vat-config/${this.updateId}`, params)
        .then(res => {
          if (res.data.status) {
            this.resetVatConfig()
            this.getVatConfigGridData()
            this.$bvModal.hide('franchise-revenue-modal')
            FemsToastrService.success(res?.data.message)
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.data.message)
        })
    },
    getVatConfigGridData() {
      if (!this.vatConfigSessionId) {
        this.rows = []
        return
      }
      const token = localStorage.getItem('accessToken')
      this.$http
        .get(`${window.apiUrl}accounting/vat-config/read/${this.vatConfigSessionId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.data.status) {
            this.rows = res.data.data
          } else {
            this.rows = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    refreshGrid() {
      this.getVatConfigGridData()
    },
    resetVatConfig() {
      this.updateId = null
      this.vatConfigFormData = {}
      this.monthsState = null
      this.percentageState = null
    },
    openModal() {
      if (!this.vatConfigSessionId) {
        FemsToastrService.error('Session must be selected')
        return
      }
      this.resetVatConfig()
      this.getVatConfigMonths()
      this.$bvModal.show('franchise-revenue-modal')
    },
    editRow(rowData) {
      this.getVatConfigMonths()
      this.updateId = rowData.id
      this.vatConfigFormData = {
        id: rowData.id,
        months: JSON.parse(rowData.months.ids) || [],
        percentage: rowData.vat_percentage,
        status: rowData.status,
      }
      this.vatConfigSessionId = rowData.aca_session_id
      this.$bvModal.show('franchise-revenue-modal')
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`${window.apiUrl}accounting/vat-config/${rowData.id}`)
            .then(res => {
              if (res.status && res.data.status) {
                this.resetVatConfig()
                this.getVatConfigGridData()
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: res.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else {
                FemsToastrService.error(res?.data.message)
              }
            })
        }
      })
    },

    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
